import { Controller } from 'stimulus'; 
import * as d3 from 'd3';

export default class extends Controller {
  // static targets = ["timezone"]

  connect() {
    const square = d3.selectAll("rect");
    square.style("fill", "orange");

  }

  
 
}

