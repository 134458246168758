// The rest is fontawesome
import { icon, library, dom, config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false;
config.mutateApproach = 'sync';
config.keepOriginalSource = false;

import {
  faCalendarDay,
  faCalendarStar,
  faMedal,
  faShirtRunning,
  faUserGear,
  faSquareSliders,
  faArrowRightFromBracket,
  faPlanetMoon,
  faStarShooting,
  faSatellite,
  faUserAstronaut,
  faComet,
  faHeadSideHeart,
  faBellSlash
} from '@fortawesome/pro-duotone-svg-icons';

import {
} from '@fortawesome/pro-solid-svg-icons';

import {
} from '@fortawesome/pro-light-svg-icons';

library.add(
  faCalendarDay,
  faCalendarStar,
  faMedal,
  faShirtRunning,
  faUserGear,
  faSquareSliders,
  faArrowRightFromBracket,
  faPlanetMoon,
  faStarShooting,
  faSatellite,
  faUserAstronaut,
  faComet,
  faHeadSideHeart,
  faBellSlash
)

dom.watch()



