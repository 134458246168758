import { Controller } from 'stimulus'; 

function selectElement(id, valueToSelect) {    
  let element = document.getElementById(id);
}

export default class extends Controller {
  static targets = ["timezone", "locale"]

  connect() {
    if (this.timezoneTarget.value == '') {
      this.timezoneTarget.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    if (this.localeTarget.value == '') {
      const getLanguage = () => navigator.userLanguage || (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || navigator.systemLanguage || 'en';
      this.localeTarget.value = getLanguage();
    }
  }
 
  greet() {
  }
}

