import { Controller } from 'stimulus'; 

// function selectElement(id, valueToSelect) {    
//   let element = document.getElementById(id);
// }

export default class extends Controller {
  static targets = ["mute", "muteUntil"]

  connect() {
    this.setMuteExpirationTimeVisibility(this.muteTarget.checked)

    // if (this.timezoneTarget.value == '') {
    //   this.timezoneTarget.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // }

    // if (this.localeTarget.value == '') {
    //   const getLanguage = () => navigator.userLanguage || (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || navigator.systemLanguage || 'en';
    //   this.localeTarget.value = getLanguage();
    // }
  }

  muteToggle(e) {
    this.setMuteExpirationTimeVisibility(e.target.checked)
  }

  setMuteExpirationTimeVisibility(isVisible) {
    if (isVisible == true) {
      this.muteUntilTarget.classList.add('visible')
    } else {
    // for (let optionTag of document.getElementsByTagName("option")) {
    //   optionTag.removeAttribute('selected')
    // }

      this.muteUntilTarget.classList.remove('visible')
    }

  }
 
}
