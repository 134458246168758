import { Controller } from 'stimulus'; 
import moment from 'moment';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import calendar from 'dayjs/plugin/calendar';

dayjs.extend(relativeTime)
dayjs.extend(calendar)

function selectElement(id, valueToSelect) {    
  let element = document.getElementById(id);
}

export default class extends Controller {
  // static targets = ["timezone"]

  connect() {
    let thisTime = dayjs(this.element.dataset.time).calendar()

    this.element.innerHTML = thisTime;
  }
 
}

